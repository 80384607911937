import { createRouter, createWebHistory } from 'vue-router'
import Filesets from '@/views/Filesets'
import FAQ from '@/views/FAQ'
import ExternalView from '@/views/ExternalView'
import TutorialVideos from '@/views/TutorialVideos'
import SDK from '@/views/SDK'
import InternalDatasetSettings from '@/views/admin/InternalDatasetSettings'
import ExternalDatasetSettings from '@/views/admin/ExternalDatasetSettings'
import FilesetSettings from '@/views/admin/FilesetSettings'
import ConfigurationSettings from '@/views/admin/ConfigurationSettings'
import DocSettings from '@/views/admin/DocSettings'
import FlowGroupSettings from '@/views/admin/FlowGroupSettings'
import FlowEnvironmentSettings from '@/views/admin/FlowEnvironmentSettings'
import NiFlowFlows from '@/views/niflow/NiFlowFlows'
import NiFlowRequests from '@/views/niflow/NiFlowRequests'
import FlowRequest from '@/views/niflow/FlowRequest'
import MainFlowHistory from '@/views/niflow/MainFlowHistory'
import JoltSpecificationSettings from '@/views/admin/JoltSpecificationSettings'
import UnifiedDatasets from '@/views/UnifiedDatasets'
import SearchResults from '@/views/SearchResults'
import About from '@/views/admin/About'
import Login from '@/views/Login'
import UserProfile from '@/views/UserProfile'
import ChangePassword from '@/views/ChangePassword'
import ManageCac from '@/views/ManageCac'
import UserSettings from '@/views/admin/UserSettings'
import Auditing from '@/views/admin/Auditing'
import ApiKeys from '@/views/ApiKeys'
import NoticeSettings from '@/views/admin/NoticeSettings'
import Notices from '@/views/Notices'

const routes = [
  {
    path: '/',
    redirect: { name: 'UnifiedDatasets' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/manage-cac',
    name: 'ManageCac',
    component: ManageCac
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/search',
    alias: ['/datasets', '/reports', '/external-datasets'],
    name: 'UnifiedDatasets',
    component: UnifiedDatasets,
    children: [
      {
        path: ':searchId',
        name: 'SearchResults',
        component: SearchResults,
        props: true
      }
    ]
  },
  {
    path: '/filesets',
    alias: '/files',
    name: 'Filesets',
    component: Filesets
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/tutorials',
    name: 'TutorialVideos',
    component: TutorialVideos
  },
  {
    path: '/api-docs',
    name: 'ApiDocs',
    component: ExternalView,
    props () {
      return { view: 'apiDocumentation' }
    }
  },
  {
    path: '/reporting',
    name: 'Kibana',
    component: ExternalView,
    props () {
      return { view: 'kibana' }
    }
  },
  {
    path: '/metadata',
    name: 'DataHub',
    component: ExternalView,
    props () {
      return { view: 'dataHub' }
    }
  },
  {
    path: '/java-sdk',
    name: 'JavaSDK',
    component: SDK,
    props () {
      return {
        readmeFile: 'SDK/Java_README.md',
        downloadFile: 'SDK/Java-SDK.zip'
      }
    }
  },
  {
    path: '/python-sdk',
    name: 'PythonSDK',
    component: SDK,
    props () {
      return {
        readmeFile: 'SDK/Python_README.md',
        downloadFile: 'SDK/Python-SDK.zip'
      }
    }
  },
  {
    path: '/internal-dataset-settings',
    name: 'InternalDatasetSettings',
    component: InternalDatasetSettings
  },
  {
    path: '/external-dataset-settings',
    name: 'ExternalDatasetSettings',
    component: ExternalDatasetSettings
  },
  {
    path: '/fileset-settings',
    name: 'FilesetSettings',
    component: FilesetSettings
  },
  {
    path: '/configuration-settings',
    name: 'ConfigurationSettings',
    component: ConfigurationSettings
  },
  {
    path: '/doc-settings',
    name: 'DocSettings',
    component: DocSettings
  },
  {
    path: '/flowgroup-settings',
    name: 'FlowGroupSettings',
    component: FlowGroupSettings
  },
  {
    path: '/flowenvironment-settings',
    name: 'FlowEnvironmentSettings',
    component: FlowEnvironmentSettings
  },
  {
    path: '/niflow-flows',
    name: 'NiFlowFlows',
    component: NiFlowFlows,
    children: [
      {
        path: ':groupName/:flowId',
        name: 'MainFlowHistory',
        component: MainFlowHistory,
        props: (route) => {
          /**
           * This would preserve the other route.params object properties overriding only
           * `flowId` in case it exists with its integer equivalent, or otherwise with
           * undefined.
           */
          return { ...route.params, ...{ flowId: Number.parseInt(route.params.flowId, 10) || undefined } }
        }
      }
    ]
  },
  {
    path: '/niflow-requests',
    name: 'NiFlowRequests',
    component: NiFlowRequests,
    children: [
      {
        path: ':groupName/:flowId/:requestId',
        name: 'FlowRequest',
        component: FlowRequest,
        props: (route) => {
          return { ...route.params, ...{ flowId: Number.parseInt(route.params.flowId, 10) || undefined, requestId: Number.parseInt(route.params.requestId, 10) || undefined } }
        }
      }
    ]
  },
  {
    path: '/jolt-settings',
    name: 'JoltSpecificationSettings',
    component: JoltSpecificationSettings
  },
  {
    path: '/user-settings',
    name: 'UserSettings',
    component: UserSettings
  },
  {
    path: '/auditing',
    name: 'Auditing',
    component: Auditing
  },
  {
    path: '/keys',
    name: 'ApiKeys',
    component: ApiKeys
  },
  {
    path: '/notice-settings',
    name: 'NoticeSettings',
    component: NoticeSettings
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
