<template>
  <div class="w-full h-full">
    <Teleport to="#fullscreen-map-target" :disabled="!isFullscreen">
      <div id="mapContainer" :class="[{'w-full h-full': !isFullscreen}, {'w-screen h-screen': isFullscreen}]">
        <span class="p-1 bg-white rounded absolute right-4 top-10px border-2 border-gray-400 cursor-pointer z-9999 inline" :title="fullscreenTitle" @click="isFullscreen = !isFullscreen">
          <i :class="['fas text-xl', {'fa-expand': !isFullscreen}, {'fa-compress': isFullscreen}]" />
        </span>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

export default {
  name: 'map-component',
  props: {
    locationPinLatLng: { type: Array, required: false, default: null }
  },
  emits: ['map-init', 'map-click'],
  data () {
    return {
      map: null,
      pinMarker: null,
      isFullscreen: false,
      scrollOffset: {
        x: 0,
        y: 0
      }
    }
  },
  computed: {
    mapLayers () {
      return this.$store.state.mapLayers
    },
    fullscreenTitle () {
      if (this.isFullscreen) {
        return 'Minimize the map'
      } else {
        return 'Maximize the map'
      }
    }
  },
  watch: {
    locationPinLatLng () {
      this.updatePinMarker()
    },
    isFullscreen () {
      this.map.off('click')
      this.map.remove()
      const self = this
      nextTick(() => {
        if (self.isFullscreen) {
          self.scrollOffset.x = window.scrollX
          self.scrollOffset.y = window.scrollY
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          document.body.style.overflow = 'hidden'
        } else {
          window.scrollTo({ top: self.scrollOffset.y, left: self.scrollOffset.x, behavior: 'smooth' })
          document.body.style.overflow = 'scroll'
        }
        self.setupLeaflet()
      })
    }
  },
  methods: {
    setupLeaflet () {
      const center = this.locationPinLatLng !== null ? this.locationPinLatLng : [0, 0]
      const map = L.map('mapContainer', { tap: false }).setView(center, 0)
      this.mapLayers.forEach(mapLayer => {
        L.tileLayer(mapLayer.urlFormat,
          {
            id: mapLayer.id,
            attribution: mapLayer.attribution,
            maxZoom: mapLayer.maxZoom,
            noWrap: true
          }
        ).addTo(map)
      })
      const self = this
      map.on('click', event => {
        self.$emit('map-click', event.latlng)
      })

      if (this.locationPinLatLng === null) {
        map.fitWorld()
      }
      this.map = map

      this.$emit('map-init', map)
      this.updatePinMarker()
    },
    updatePinMarker () {
      if (this.pinMarker !== null) {
        this.pinMarker.removeFrom(this.map)
        this.pinMarker = null
      }

      if (this.locationPinLatLng !== null) {
        this.pinMarker = L.marker(this.locationPinLatLng)
        this.pinMarker.addTo(this.map)
      }
    }
  },
  mounted () {
    this.setupLeaflet()
  },
  unmounted () {
    this.map.off('click')
    this.map.remove()
  }
}
</script>
