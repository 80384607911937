<template>
  <table class="w-full mt-2">
    <thead class="w-full text-white sticky top-0 bg-theme-400 z-999">
      <td class="px-1 border-r border-black">{{idColumn}}</td>
      <td v-for="column in columns" :key="column.field" class="px-1 border-r border-black">
        {{ column.display }}
      </td>
      <td v-if="showActions" class="px-1"></td>
    </thead>
    <tbody class="w-full overflow-y-auto">
      <tr v-for="(entity, idx) in entities" :key="entity[idColumn]" :title="entity[idColumn]" :class="['py-1 h-16 w-full hover:bg-theme-200 max-w-full overflow-hidden', {'bg-theme-100': idx % 2 === 0}]">
        <td class="break-all px-1 border-r border-black"><i v-if="!disabledActions.includes('disable')" :class="['fas fa-circle mr-2', { 'text-emerald-600': entity.enabled }, { 'text-rose-600': !entity.enabled }]" :title="entity.enabled ? 'enabled' : 'disabled'"></i>{{entity[idColumn]}}</td>
        <td v-for="column in columns" :key="column.field" class="break-all px-1 border-r border-black">
          {{ entity[column.field] }}
        </td>
        <td v-if="showActions" class="break-all px-1 text-center">
          <i ref="actionMenu" class="fas fa-ellipsis text-theme-400 hover:text-theme-100 cursor-pointer text-2xl" title="actions" @click="setEntityAction(entity)"></i>
          <div v-if="entityAction !== null && entityAction[idColumn] === entity[idColumn]" class="flex flex-col absolute right-1 border border-black bg-white rounded text-sm z-100 cursor-pointer">
            <div v-if="!disabledActions.includes('edit')" class="flex border-b border-black items-center p-1 hover:bg-theme-400 hover:text-white" @click="emit('edit', entity)">
              <i class="fas fa-pencil mr-2"></i>Edit
            </div>
            <div v-if="!disabledActions.includes('copy')" class="flex border-b border-black items-center p-1 hover:bg-theme-400 hover:text-white" @click="emit('copy', entity)">
              <i class="fas fa-copy mr-2"></i>Copy
            </div>
            <div v-if="!disabledActions.includes('export')" class="flex border-b border-black items-center p-1 hover:bg-theme-400 hover:text-white" @click="emit('export', entity)">
              <i class="fas fa-file-export mr-2"></i>Export
            </div>
            <div v-if="!disabledActions.includes('disable')" class="flex border-b border-black items-center p-1 hover:bg-theme-400 hover:text-white" @click="emit('toggle-disabled', entity)">
              <i class="fas fa-toggle-on mr-2"></i>Set {{ entity.enabled ? 'Disabled' : 'Enabled' }}
            </div>
            <div v-if="!disabledActions.includes('delete')" class="flex items-center p-1 hover:bg-theme-400 hover:text-white" @click="emit('delete', entity)">
              <i class="fas fa-trash mr-2"></i>Delete
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="entities.length === 0">
        <td class="text-center text-gray-700" :colspan="columns.length + 1">no matching {{entityTypeDisplay.toLowerCase()}}s</td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  name: 'entity-editor',
  components: {
  },
  emits: ['edit', 'copy', 'toggle-disabled', 'delete'],
  props: {
    idColumn: { type: String, required: true },
    disabledActions: { type: Array, required: true },
    showActions: { type: Boolean, required: true },
    entityTypeDisplay: { type: String, required: true },
    columns: { type: Array, required: true },
    entities: { type: Array, required: true }
  },
  data () {
    return {
      entityAction: null
    }
  },
  methods: {
    setEntityAction (entity) {
      const self = this

      if (this.entityAction !== null && this.entityAction[this.idColumn] === entity[this.idColumn]) {
        this.closeActionMenu()
        return
      }

      this.entityAction = entity
      this.$nextTick(() => {
        document.addEventListener('click', self.handleClickOutside)
      })
    },
    emit (eventName, entity) {
      this.$emit(eventName, entity)
      this.closeActionMenu()
    },
    handleClickOutside (event) {
      const isClickOutside = this.$refs.actionMenu.every(menu => !menu.contains(event.target))

      if (isClickOutside) {
        this.closeActionMenu()
      }
    },
    closeActionMenu () {
      this.entityAction = null
      document.removeEventListener('click', this.handleClickOutside)
    }
  },
  beforeUnmount () {
    this.closeActionMenu()
  }
}
</script>
