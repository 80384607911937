<template>
  <div v-if="isUserAdmin && deployments !== null && deployments.length > 0" class="px-2 flex items-center gap-1 rounded-xl bg-theme-500 text-white text-xs cursor-pointer hover:bg-theme-300" title="Deployment History" @click.stop.prevent="showHistory = true">
    <i class="fas fa-paper-plane"/>
    <span v-if="deployments === null">-</span>
    <span v-else>{{ deployments.length }}</span>
  </div>
  <modal v-if="showHistory" class="cursor-default" @click.stop.prevent>
    <template v-slot:header>
      <h1 class="text-2xl">{{ iterationName }} Deployment History</h1>
    </template>
    <template v-slot:body>
      <table class="w-full mt-2">
        <thead class="w-full text-white sticky top-0 bg-theme-400 z-999">
          <td class="px-1 border-r border-black">Deploy Time</td>
          <td class="px-1 border-r border-black">Deployed By</td>
          <td class="px-1 border-r border-black">Environment Name</td>
          <td class="px-1 border-black">Base URL</td>
        </thead>
        <tbody class="w-full overflow-y-auto">
          <tr v-for="(deployment, idx) in deployments" :key="deployment.id" :class="['py-1 h-16 w-full max-w-full overflow-hidden', {'bg-theme-100': idx % 2 === 0}]">
            <td class="break-all px-1 border-r border-black">{{ absoluteTime(deployment.deployedTimeMs) }}</td>
            <td class="break-all px-1 border-r border-black">{{ deployment.deployedByUsername }}</td>
            <td class="break-all px-1 border-r border-black">{{ deployment.deployedEnvironmentName }}</td>
            <td class="break-all px-1 border-black">{{ deployment.deployedBaseUrl }}</td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:footer>
      <button class="btn btn-theme" @click.stop.prevent="showHistory = false"><i class="fas fa-x mr-1" />Close</button>
    </template>
  </modal>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import Modal from '@/components/Modal'
import UserRolesMixin from '@/mixins/UserRolesMixin'

export default {
  name: 'iteration-deployment-badge',
  components: {
    Modal
  },
  mixins: [UserRolesMixin],
  props: {
    iterationId: { type: Number, required: true },
    iterationName: { type: String, required: true }
  },
  data () {
    return {
      deployments: null,
      showHistory: false
    }
  },
  computed: {
    isUserAdmin () {
      return this.userRoles.includes('admin')
    }
  },
  watch: {
    iterationId () {
      this.loadDeployments()
    }
  },
  methods: {
    absoluteTime (timeMs) {
      return moment(timeMs).format('MM-DD-YYYY, h:mm:ss a')
    },
    addDeployment (deployment) {
      if (this.deployments === null) return
      this.deployments.push(deployment)
    },
    async loadDeployments () {
      if (this.iterationId === null || !this.isUserAdmin) return
      this.deployments = null

      try {
        const response = await this.$store.dispatch('niFlow/fetchDeployments', this.iterationId)
        this.deployments = _.sortBy(response.data, 'deployedTimeMs')
      } catch (error) {
        console.error('An error occurred loading iteraiton deployments', error)
      }
    }
  },
  mounted () {
    this.loadDeployments()
  }
}
</script>
